.section.improveSkill{
    .col.img{
        img{
            width: 100%;
        }
    }
    .col.typography{
        padding-left: 1em;
        .skill-item{
            border-left: .2em solid var(--primary-color);
            margin-bottom: 0.5em;
            padding: .1em 0 .1em .5em;
            color: var(--text-light);
            font-weight: bold;
            transform: translateX(100%);
            opacity: 0;
            animation: getAnimationDuration(0.3s) slideInRight forwards;
            @for $i from 2 through 7{
                &:nth-child(#{$i}){
                    animation-delay: #{$i * .1}s;
                }
            }
        }
        .btn{
            margin-top: .75em;

        }
    }
}