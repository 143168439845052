@keyframes slideInLeft{
    0%{
        transform: translateX(-100%);
    }
    100%{
        transform: translateX(0);
    }
}

@keyframes popOut{
    from{
        transform: scale(0);
        opacity: 0;
    }
    to{
        transform: scale(1);
        opacity: 1;
    }
}

@keyframes slideInRight{
    from{
        transform: translateX(100%);
        opacity: 0;
    }
    to{
        transform: translateX(0);
        opacity: 1;
    }
}