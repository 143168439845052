:root {
    --background-color: #fff;
    --background-light: #fff;
    --shadow-color: rgba(0, 0, 0, 0.2);
    --primary-color: rgb(6, 39, 255);
    --text-color: #0A0A0A;
    --text-light: #575757;
    --font-size: 16px;
    --animatiom-speed: 1;
   --1st-color: rgb(204, 255, 51, 0.5);
    --secondary-color: rgb(255, 255, 255, 0.9);
}

@function getAnimationDuration($duration :number) {
    @return calc($duration * var(--animatiom-speed));
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    background-color: var(--background-light);
    font-size: var(--font-size);
    font-family: 'Roboto', 'sans-serif';
    padding-top: 55px;
}

a {
    text-decoration: none;
}

img {
    object-fit: cover;
}

@import "partials/navbar.scss";

.btn {
    border: none;
    outline: none;
    background: var(--primary-color);
    color: #fff;
    padding: .75em 1em;
    text-transform: uppercase;
    font-size: 1em;
    font-weight: bold;
    letter-spacing: 1px;
    border-radius: 3px;
    box-shadow: 0 2px 6px var(--shadow-color);

    &:hover {
        background: #fff;
        color: var(--primary-color);
        border: 1px 3px var(--primary-color);
        cursor: pointer;
    }
}

.custom-image {
    width: 100%;
    height: 0;
    // background-color: red;
    position: relative;

    img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}

.section {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 3vw;

    &.d-block {
        display: block;
    }

    .title {
        color: var(--text-color);
        font-size: 2.5em;
        margin-bottom: .25em;
        font-weight: 900;
    }

    .info {
        color: var(--text-light);
        font-size: 1em;
        margin-bottom: 1.25em;
        letter-spacing: 1px;
        line-height: 1.3;

    }

    .col {
        width: 50%;

    }
}



@import "partials/__heroSection.scss";

@import "partials/improve.scss";

@import "partials/Quote";

@import "partials/chiefSection";

@import "partials/Footer";

@import "partials/PreviousSearches";

@import "partials/RecipeSection";

@import "partials/style";

@import "partials/__recommend";

.options-conatiner {
    box-shadow: 0 5px 10px var(--shadow-color);
    padding: 1em;
    border-radius: 5px;
    border-left: .5em solid var(--primary-color);
    background-color: var(--background-light);
    margin-top: 1em;
    display: flex;
    gap: 1em;

    .option {
        height: 3.5em;
        width: 3.5em;
        box-shadow: 0 3px 6px var(--shadow-color);
        border-radius: 5px;
        display: flex;
        justify-content: center;
        align-items: center;

        &.light {
            background-color: #fff;
        }

        &.dark {
            background-color: rgb(29, 29, 29);
        }

        .check {
            height: 2.5em;
            width: 2.5em;
            background-color: var(--primary-color);
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            box-shadow: 0 2px 5px var(--shadow-color);

            svg {
                height: 1.5em;
                width: 1.5em;
            }
        }
    }

    .btn span {
        display: inline-block;
        margin-left: .5em;
    }
}

.d-block h2 {
    font-size: 1.75em;
    margin-top: .7em;
    color: var(--text-color);
}

.main {
    padding-block: 3vw;
}

.container {
    padding-inline: 25vw;
}



@import "partials/animation.scss";

@media screen and (max-width: 1280px) {
    .container {
        padding-inline: 18vw;
    }
}

@media screen and (max-width: 1050px) {
    .container {
        padding-inline: 12vw;
    }
}

@media screen and (max-width: 768px) {
    .navbar {
        .nav-links {
            display: none;
        }

        .sidebar-btn {
            display: block;
        }
    }

    .section {
        .col {
            width: 100%;
            padding: 0 !important;
            text-align: center;
            margin-bottom: 2em;
        }
    }

    .skill-item {
        display: inline-block;
        margin: .75em;
    }

    .section.improve-skill .btn {
        display: block;
        margin-left: 50%;
        transform: translateX(-50%);
    }

    .cheif-card {
        box-shadow: 0 5px 10px var(--shadow-color);
    }

    .container {
        padding-inline: 6vw;
    }

    .footer {
        grid-template-columns: repeat(4, 1fr);

        .footer-section:nth-child(1) {
            grid-column: span 4;
        }

        .footer-section:nth-child(2),
        .footer-section:nth-child(3) {
            grid-column: span 2;
        }
    }
}

@media screen and (max-width: 480px) {
    .container {
        padding-inline: 3vw;
    }
}