.recipe-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-color: #f5f5f5;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  margin: 20px;
  width: 100%;
  max-width: 500px;

}

.form-label {
  font-size: 18px;
  margin-bottom: 10px;

}

.form-input {
  width: 100%;
  font-size: 16px;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  margin-bottom: 20px;


  &:focus {
    outline: none;
    border: 1px solid var(--primary-color);

  }
}

.form-button {
  font-weight: 700;
  padding: 10px 20px;
  background-color: var(--primary-color);
  color: #fff;
  font-size: 16px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;


  &:hover {
    color: var(--primary-color);
    background-color: #fff;

  }
}

.recommendations-list {
  margin-top: 30px;
  list-style: none;
  padding: 0;
}

.recommendation-item {
  font-size: 18px;
  margin-bottom: 10px;
  padding: 10px;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;

}

.recommendation-rank {
  font-weight: bold;
  margin-right: 10px;

}
